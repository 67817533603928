import React, { Component } from 'react';

import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';

import Dialog from '../../components/Dialog/Dialog';
import { ButtonSecondary, ButtonDefault } from '../../components/Button/Button';

export class DialogRemove extends Component {
    constructor(props) {
        super(props);
        props.parentRef(this);
        this.state = {
            dialog: {
                open: false,
                title: null,
                text: null,
                id: null,
                loading: false,
            },  
        }
    }

    // LOADING AO SUBMETER MODAL
    loading = () => {
        this.setState({
            dialog: {
                ...this.state.dialog,
                loading: true,
            }
        });
    }

    // AÇÕES MODAL
    actions = () => {
        return (
            <>
                <ButtonSecondary 
                    onClick={() => this.props.handleConfirm(this.state.dialog.id)}
                    startIcon={this.state.dialog.loading ? <CircularProgress size={16} /> : <CheckOutlinedIcon />}
                    disabled={this.state.dialog.loading}
                    style={{ margin: 4 }}
                    name="Remover"
                />
                <ButtonDefault 
                    onClick={this.close}
                    startIcon={<ClearOutlinedIcon />}
                    style={{ margin: 4 }}
                    name="Cancelar"
                />
            </>
        )
    }

    // FECHAR MODAL
    close = () => {
        this.setState({ 
            dialog: { 
                open: false, 
                title: null,
                text: null,
                id: null,
                loading: false,
            }
        })
    }

    render() {
        return (
            <>
                <Dialog
                    openDialog={this.state.dialog.open}
                    title={this.state.dialog.title}
                    closeDialog={this.close}
                    actions={this.actions()}
                    fullWidth={true}
                    maxWidth={"md"}
                >
                    {this.state.dialog.text}
                </Dialog>
            </>
        )
    }
}

export default DialogRemove;